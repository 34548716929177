import { Typography, Accordion, AccordionSummary, AccordionDetails, Link } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';

export const TermsAndConditions = ({ product }) => {
    let link;

    if (product === 'paylater') {
        link = 'https://www.golendica.com/agreement-paylater';
    } else if (product === 'drawdown') {
        link = 'https://www.golendica.com/agreement-drawdown';
    } else if (product === 'fundnow') {
        link = 'https://www.golendica.com/agreement-fundnow';
    } else {
        link = 'https://www.golendica.com/agreement-paylater';
    }

    return (
        <Accordion
            square={true}
            sx={{
                boxShadow: 0,
                borderTop: '1px solid #D9D9E5',
                borderBottom: '1px solid #D9D9E5',
                mb: 2,
                background: 'inherit',
            }}
        >
            <AccordionSummary expandIcon={<ArrowDropDown />}>
                <Typography variant="body2">Terms and conditions</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography variant="body2">
                    The terms and conditions related to your Future Receivable Sales Agreement can
                    be found{' '}
                    <Link
                        variant="inherit"
                        color="text.primary"
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        underline="always"
                    >
                        here
                    </Link>
                    . Please review before signing.
                </Typography>
            </AccordionDetails>
        </Accordion>
    );
};
