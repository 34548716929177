import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, IconButton, Link, Typography } from '@mui/material';
import { ImgWithFallback, Logo, FeatureLayout } from '@lendica/components';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import PreviewIcon from '@mui/icons-material/Preview';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';

import { updateCompanyConsent } from '../api';
import { useApplication } from '../context/useApplication';

const sections = [
    {
        title: 'What does Lendica collect?',
        subtitle:
            'Lendica collects your company’s sales and operational data for underwriting purposes. There’s no impact on your credit score even if you’re rejected.',
        Icon: CloudDoneIcon,
    },
    {
        title: 'You own your data',
        subtitle:
            'Lendica uses your company’s data for underwriting purposes only. We will never sell your data to any third parties.',
        Icon: PreviewIcon,
    },
];

export const ConsentPage = ({ handleClose, handleNext }) => {
    const navigate = useNavigate();

    const { partnerName, toggleConsent, searchParams } = useApplication();
    const cleanPartner = str => {
        // Remove everything after the first underscore
        let cleanedStr = str.replace(/_.*$/, '');

        // Capitalize the first letter and add the rest of the string
        return cleanedStr.charAt(0).toUpperCase() + cleanedStr.slice(1);
    };

    const handleAcceptConsent = async () => {
        try {
            await updateCompanyConsent(true);
            toggleConsent();
            (!!handleNext && handleNext()) ||
                navigate(
                    `../application${searchParams.size > 0 ? `?${searchParams.toString()}` : ''}`
                );
        } catch {
            console.log('Consent is not accepted');
        }
    };

    return (
        // <Box
        //     sx={{
        //         height: '100%',
        //         display: 'flex',
        //         flexDirection: 'column',
        //         boxSizing: 'border-box',
        //     }}
        // >
        <>
            {/* <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    pt: 1,
                    px: 3,
                    boxSizing: 'border-box',
                    gridArea: 'header',
                }}
            >
                <Logo height={18} color="#AEAEBE" />

                <IconButton size="small" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </Box> */}
            <Box
                display="flex"
                flexDirection="column"
                flexGrow={1}
                sx={{ gap: 3, pt: 3, pb: 8, px: 3, overflowY: 'auto' }}
            >
                <Box>
                    <Typography sx={{ textAlign: 'center', fontSize: 21, fontWeight: 400 }}>
                        {partnerName ? `${cleanPartner(partnerName)} + ` : null}{' '}
                        <Logo height={18} color="#0E0E2C" />
                    </Typography>
                </Box>

                <Typography sx={{ textAlign: 'center', fontWeight: 400 }}>
                    {partnerName
                        ? `${cleanPartner(
                              partnerName
                          )} uses Lendica to help you free up cash flow with affordable finance, on
                    demand.`
                        : `Lendica helps you free up cash flow with affordable finance, on demand.`}
                </Typography>

                <Box sx={{ width: 240, margin: '0 auto' }}>
                    <ImgWithFallback
                        src="https://static.golendica.com/assets/img/consent.webp"
                        fallback="https://static.golendica.com/assets/img/consent.png"
                    />
                </Box>

                {sections.map(({ title, subtitle, Icon }, index) => (
                    <Box
                        key={index}
                        sx={{ backgroundColor: '#F5F5F5', borderRadius: '4px', px: 2, py: 1.75 }}
                    >
                        <Typography sx={{ display: 'flex', gap: 1.5, fontWeight: 500 }}>
                            <Icon sx={{ color: '#8C8CA1' }} /> {title}
                        </Typography>

                        <Typography sx={{ paddingLeft: 4.75, fontWeight: 400 }}>
                            {subtitle}
                        </Typography>
                    </Box>
                ))}
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                sx={{ pt: 1, pb: 2, px: 3, height: 'fit-content', gap: 1 }}
            >
                <Box>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            fontSize: 12,
                            fontWeight: 400,
                            color: '#4A4A68',
                        }}
                    >
                        *By clicking ‘Agree and Continue’ you agree to Lendica’s
                    </Typography>
                    <Link underline="hover" href="https://golendica.com/privacy" target="_blank">
                        <Typography
                            sx={{
                                textAlign: 'center',
                                textDecoration: 'underline',
                                fontSize: 12,
                                fontWeight: 500,
                                color: '#4A4A68',
                            }}
                        >
                            Privacy Policy.
                        </Typography>
                    </Link>
                </Box>

                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleAcceptConsent}
                    endIcon={<CheckCircleIcon />}
                >
                    Agree and Continue
                </Button>

                <Button fullWidth onClick={() => navigate('/learn-more')}>
                    Learn More about Lendica
                </Button>
            </Box>
        </>
    );
};
