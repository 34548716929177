import { useEffect } from 'react';

import { StatusPage } from './components/StatusPage';
import { useBillData } from './AppData';
import { matchHostname } from '@lendica/utils';
import { flag } from './lib/states';
import { redirectToUrl } from './lib/actors';

// NEW FUNCTION THAT REDIRECTS TO RETURN URL IMMEDIATELY.
// USE FOR THE /checkout/redirect route
export const CheckoutRedirect = () => {
    const { bill } = useBillData();

    // action to use bill.return_url if it exists
    useEffect(() => {
        const url = bill.return_url || 'https://www.golendica.com/';
        redirectToUrl(url);
    }, [bill]);

    return null;
};

export const CheckoutApproved = () => {
    const { bill } = useBillData();

    if (flag.hasReturnUrl(bill)) {
        return <CheckoutRedirect />;
    }

    return (
        <StatusPage
            title="Congrats! You are ready to go."
            subtitle="You can track your payments and view reports in the portal."
            duration={25}
            actionLabel="Go Now"
            action={() => {
                redirectToUrl(`https://portal.${matchHostname()}/`);
            }}
        />
    );
};

export const ShopifyPending = () => {
    const { bill } = useBillData();

    return (
        <StatusPage
            title="Your application is being processed."
            subtitle="Typical response time is 24-48 hours. You can track your progress in the portal."
            duration={20}
            actionLabel="Go To Portal"
            action={() => {
                redirectToUrl(`https://portal.${matchHostname()}/`);
            }}
            secondaryActionLabel={bill.web_url ? 'Checkout w/o PayLater' : null}
            secondaryAction={bill?.web_url ? () => redirectToUrl(bill?.web_url) : null}
        />
    );
};

export const ShopifyRejected = () => {
    const { bill } = useBillData();
    return (
        <StatusPage
            title="Sorry, your application was not approved this time."
            subtitle="You may complete checkout without Lendica PayLater."
            // duration={20}
            actionLabel={bill?.web_url ? 'Checkout w/o PayLater' : null}
            action={bill?.web_url ? () => redirectToUrl(bill?.web_url) : null}
        />
    );
};

export const ExceedsLimit = () => {
    const { bill } = useBillData();

    // If there's a bill.return_url, redirect using CheckoutRedirect
    if (flag.hasReturnUrl(bill)) {
        return <CheckoutRedirect />;
    }

    return (
        <StatusPage
            title="Sorry, you've reached the credit limit"
            subtitle="We couldn't approve the deal at this time. You may complete checkout without Lendica PayLater."
            actionLabel="Go to Portal"
            action={() => {
                redirectToUrl(`https://portal.${matchHostname()}/`);
            }}
            secondaryActionLabel={bill.web_url ? 'Checkout w/o PayLater' : null}
            secondaryAction={bill?.web_url ? () => redirectToUrl(bill?.web_url) : null}
        />
    );
};
