import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDataContext } from '../AppData';

import { SelectPaymentTerms } from '@lendica/select-paylater-terms';

export const SelectPaymentTermsView = ({ onTermSelected, onSelected }) => {
    const { billId } = useParams();
    const { bill, offers, isLoading } = useAppDataContext();

    useEffect(() => {
        scroll({
            top: 0,
        });
    }, []);

    return (
        !isLoading &&
        !!bill &&
        !!offers && (
            <SelectPaymentTerms
                bill={bill}
                offers={offers}
                onTermSelected={offer => onTermSelected(billId, offer)}
                onSelected={offer => onSelected(billId, offer)}
            />
        )
    );
};
