import { forwardRef } from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';
import { Logo } from '@lendica/components';

export const Header = forwardRef(({ leftActions, rightActions, title, showLogo = true }, ref) => (
    <Box ref={ref}>
        <Grid
            sx={{ height: 46 }}
            justifyContent="space-between"
            alignItems="center"
            container
            component={Box}
            pt={2}
            px={2}
        >
            <Grid item container justifyContent="flex-start" xs={2}>
                {showLogo && (
                    <Box
                        flexDirection="column"
                        justifyContent="center"
                        display="flex"
                        height="100%"
                    >
                        <Link
                            href="https://www.golendica.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Logo height={18} />
                        </Link>
                    </Box>
                )}
                {leftActions}
            </Grid>
            <Grid item container justifyContent="center" xs={8}>
                <Typography variant="subtitle1" color="text.primary">
                    {title}
                </Typography>
            </Grid>
            <Grid item container justifyContent="flex-end" xs={2}>
                {rightActions}
            </Grid>
        </Grid>
    </Box>
));
