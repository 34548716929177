export const flag = {
    hasReturnUrl(billOrDeal) {
        return billOrDeal && billOrDeal?.return_url;
    },
    isWholesaleCheckout(bill) {
        return (
            bill &&
            ['stash', 'mainstem'].some(vendor => bill.vendor_name.toLowerCase().includes(vendor))
        );
    },
    isShopifyCheckout({ company, bill }) {
        if (!company && !bill) return false;
        const vendorName = company?.partner_name || bill?.vendor_name || '';
        return [
            'altametrics',
            'plum',
            'teaica',
            'black label',
            'blacklabel',
            'blackreplica',
            'vizocom',
        ].some(vendor => vendorName.toLowerCase().includes(vendor));
    },
    isCheckout(bill) {
        return (
            bill &&
            [
                'altametrics',
                'plum',
                'teaica',
                'black label',
                'blacklabel',
                'blackreplica',
                'vizocom',
                'stash',
                'mainstem',
            ].some(vendor => bill?.vendor_name.toLowerCase().includes(vendor))
        );
    },
    allowSkipVendor(company) {
        const partnerName = company?.partner_name;
        if (!partnerName) return true;
        return ['versacloud', 'foundation'].some(partner =>
            partnerName.toLowerCase().includes(partner)
        );
    },
};
