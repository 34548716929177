import { PayLaterLogo, TermsAndConditions } from '@lendica/components';
import { BillCard } from '../components/BillCard';
import { PaymentDetails } from '../components/PaymentDetails';
import { useEffect, useState } from 'react';
import { LoadingScreen } from '@lendica/components';
import { Box, Typography } from '@mui/material';
import { TermCard } from '../components/TermCard';
import { DueDatePaymentCard } from '../components/DueDatePaymentCard';
import { BottomBar } from '../BottomBar';

export const SelectPaymentTerms = ({ onTermSelected, onSelected, bill, offers }) => {
    const [loading, setLoading] = useState(true);
    const [selectedOffer, setSelectedOffer] = useState(null);

    useEffect(() => {
        const getInvoiceDetails = async () => {
            setLoading(true);
            onSelect(offers[0]);
            setLoading(false);
        };
        getInvoiceDetails();
    }, []);

    const confirmDeal = async () => {
        onSelected(selectedOffer);
    };

    const onSelect = offer => {
        onTermSelected(offer);
        setSelectedOffer(offer);
    };

    return (
        <>
            {loading ? (
                <LoadingScreen />
            ) : (
                <Box
                    sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        boxSizing: 'border-box',
                        width: '100%',
                        background: 'inherit',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            // padding: 2,
                            py: 2,
                            boxSizing: 'border-box',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-evenly',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-evenly',
                                height: '100%',
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant="h5" color="text.secondary" mb={1}>
                                Pay this invoice over time
                            </Typography>

                            {<BillCard bill={bill} />}

                            <Box
                                display={'flex'}
                                alignItems="center"
                                alignSelf={'flex-start'}
                                mt={3}
                                mb={1}
                            >
                                <Typography
                                    fontSize={16}
                                    fontWeight={600}
                                    variant="subtitle1"
                                    mr={1}
                                >
                                    Select a
                                </Typography>
                                <PayLaterLogo height={18} />
                                <Typography
                                    fontSize={16}
                                    fontWeight={600}
                                    variant="subtitle1"
                                    ml={1}
                                >
                                    term
                                </Typography>
                            </Box>

                            {<DueDatePaymentCard bill={bill} offer={selectedOffer} />}

                            {offers.map((x, i) => (
                                <TermCard
                                    key={x.id}
                                    offer={x}
                                    dueDate={bill.due_date}
                                    selected={x.id === selectedOffer?.id}
                                    onSelect={onSelect}
                                    lowestWeekly={i === offers.length - 1}
                                    lowestFees={i === 0}
                                />
                            ))}
                            {<PaymentDetails offer={selectedOffer} bill={bill} darkMode={false} />}
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            my: 2,
                            mb: 3,
                            boxSizing: 'border-box',
                        }}
                    >
                        <TermsAndConditions product={'paylater'} />
                    </Box>

                    <BottomBar action={confirmDeal} label={'Activate'} />
                </Box>
            )}
        </>
    );
};
