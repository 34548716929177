import { useParams } from 'react-router-dom';
import { SummaryCard } from '../components/SummaryCard';
import { PaymentDetails } from '../components/PaymentDetails';
import { useEffect, useState } from 'react';
import { payLaterAPI } from '../payLaterAPI';
import { ChevronLeft } from '@mui/icons-material';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { Box, IconButton, Typography, Link, Button } from '@mui/material';
import { TermCard } from '../components/TermCard';
import { TermsAndConditions, LoadingScreen } from '@lendica/components';
import { BottomBar } from '../BottomBar';

export const AgreeToTerms = ({ onGoBack, offer, onAgree }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [approval, setApproval] = useState({});

    const { billId, offerId } = useParams();

    useEffect(() => {
        const getInvoiceDetails = async () => {
            setLoading(true);
            const res = await payLaterAPI.getBillOffers(billId);
            const approvalData = await payLaterAPI.getPaylaterApproval();
            setApproval(approvalData);
            setData(res);
            setLoading(false);
        };
        getInvoiceDetails();
    }, []);

    const confirmDeal = async () => {
        onAgree();
    };

    return (
        <>
            {loading ? (
                <LoadingScreen />
            ) : (
                <Box
                    sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        boxSizing: 'border-box',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            py: 2,
                            boxSizing: 'border-box',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-evenly',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Box flex={1}>
                                <IconButton
                                    onClick={onGoBack}
                                    sx={{ width: 'fit-content', alignSelf: 'flex-end' }}
                                    color="secondary"
                                    size="small"
                                >
                                    <ChevronLeft />
                                </IconButton>
                            </Box>

                            <Box flex={1} textAlign="center">
                                <Typography variant="h6" fontSize={14}>
                                    Agree on terms
                                </Typography>
                            </Box>

                            <Box flex={1}></Box>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-evenly',
                                height: '100%',
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant="h5" color="text.secondary" mt={4} mb={1}>
                                Review your payment term
                            </Typography>

                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                    mt: 2,
                                    mb: 1,
                                }}
                            >
                                <Button
                                    sx={{ alignSelf: 'flex', mb: 0.5 }}
                                    endIcon={<ChangeCircleIcon color="primary" />}
                                    onClick={() => onGoBack()}
                                >
                                    Change term option
                                </Button>
                                {data && (
                                    <TermCard
                                        disabled={true}
                                        selected={true}
                                        offer={offer}
                                        dueDate={data.bill.due_date}
                                    />
                                )}
                            </Box>

                            {/* {data && <CreditUsageCard approval={approval} dealAmount={selectedOffer.advance_amount} />} */}
                            {data && (
                                <PaymentDetails
                                    open={true}
                                    offer={offer}
                                    bill={data.bill}
                                    darkMode={false}
                                />
                            )}
                            {data && (
                                <SummaryCard
                                    invoice={data.bill}
                                    offer={offer}
                                    approval={approval}
                                />
                            )}
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            my: 2,
                            mb: 3,
                            boxSizing: 'border-box',
                        }}
                    >
                        <TermsAndConditions product={'paylater'} />
                        <Typography variant="caption">
                            *By clicking 'ACCEPT OFFER' you agree to all{' '}
                            <Link
                                variant="inherit"
                                color="text.primary"
                                href="https://www.golendica.com/agreement-paylater"
                                target="_blank"
                                rel="noopener noreferrer"
                                underline="always"
                            >
                                terms and conditions
                            </Link>{' '}
                            of PayLater product.
                        </Typography>
                    </Box>

                    <BottomBar action={confirmDeal} label={'Accept Offer'} />
                </Box>
            )}
        </>
    );
};
