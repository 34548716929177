import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import VendorSelect from './components/VendorSelect';
import VendorFormPopup from './components/VendorFormPopup';
import { useNavigate, useParams } from 'react-router-dom';
import { BottomBar } from './BottomBar';
import { useState, useEffect } from 'react';

import _ from 'lodash';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
    hasNumberOfDigits,
    isNumber,
    isNumberOfDigitsInRange,
    // isValidRoutingNumber,
    maskAccountNumber,
} from '@lendica/utils';
import { postCustomerSupport } from '@lendica/api';
import { useAppDataContext } from './AppData';
import { payLaterAPI } from '@lendica/select-payment-terms';

const skipVendorValidationSchema = yup.object({
    vendor: yup.object({
        email: yup.string().nullable().required('Required field'),
    }),
    company: yup.object({
        bank_name: yup.string().nullable().required('Required field'),
        bank_routing: yup.string().nullable().required('Required field'),
        bank_account: yup.string().nullable().required('Required field'),
    }),
});

const validationSchema = yup.object({
    vendor: yup.object({
        company_name: yup.string().nullable().required('Required field'),
        bank_name: yup.string().nullable().required('Required field'),
        bank_account: yup
            .string()
            .trim()
            .required('Required field')
            .test('match', 'Must be a number', isNumber)
            .test(
                'len',
                'Must have at least 5 and less than 18 digits',
                isNumberOfDigitsInRange(5, 18)
            ),
        bank_routing: yup
            .string()
            .trim()
            .required('Required field')
            .test('match', 'Must be a number', isNumber)
            .test('len', 'Routing number must be 9 digits', hasNumberOfDigits(9)),
        // .test('is-valid-routing-number', 'Invalid routing number', isValidRoutingNumber),
        email: yup.string().nullable().required('Required field'),
    }),
    company: yup.object({
        bank_name: yup.string().nullable().required('Required field'),
        bank_routing: yup.string().nullable().required('Required field'),
        bank_account: yup.string().nullable().required('Required field'),
    }),
});

export const ConfirmBank = ({ skipVendor = false }) => {
    const [data, setData] = useState();
    const [open, setOpen] = useState(false);
    const [addNew, setAddNew] = useState(false);
    const { billId, offerId } = useParams();

    const navigate = useNavigate();
    const { isLoading, setLoading } = useAppDataContext();

    // Handle request vendor billing info
    const [requestVendor, setRequestVendor] = useState(false);

    const requestVendorInfo = () => {
        const blocks = [
            {
                type: 'header',
                text: {
                    type: 'plain_text',
                    text: 'PayLater - Request Vendor Info',
                    emoji: true,
                },
            },
            {
                type: 'section',
                text: {
                    type: 'mrkdwn',
                    text: `Company ID: ${data?.company?.id}, \n Company Name: ${data?.company?.company_name}, \n Vendor Email: ${formik.values.vendor?.email} \n Partner: ${data?.company?.partner_name}, \n\`\`\`Company requested vendor info in PayLater.\`\`\`\n`,
                },
            },
        ];
        postCustomerSupport(blocks);
    };

    const handleCheckChange = e => {
        setRequestVendor(e.target.checked);
    };
    // end of request vendor billing info

    const checkIfBankComplete = company => {
        const pick = ({ bank_account, bank_name, bank_routing }) => ({
            bank_account,
            bank_name,
            bank_routing,
        });
        const bankFields = pick(company);
        if (_.isEmpty(bankFields)) {
            return false;
        }
        for (const value of Object.values(bankFields)) {
            if (!value) {
                return false;
            }
        }
        return true;
    };

    const formik = useFormik({
        initialValues: data ?? {
            vendor: null,
            company: {
                bank_name: '',
                bank_account: '',
                bank_routing: '',
            },
        },
        async onSubmit(values) {
            try {
                setLoading(true);
                if (requestVendor) {
                    requestVendorInfo();
                }
                await payLaterAPI.confirmBillDetails(data.bill, values.vendor, values.company);
                setLoading(false);
                navigate(`/${billId}/agree-on-terms/${offerId}`);
            } catch (e) {
                console.log(e);
                navigate('/error');
            }
        },
        validationSchema: skipVendor ? skipVendorValidationSchema : validationSchema,
        enableReinitialize: true,
    });

    // returns true is customer is empty, else returns false
    const isVendorEmpty = vendor => {
        if (vendor) {
            if (Object.values(vendor).length === 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    // returns true if customer is complete, else returns false
    const isVendorComplete = vendor => {
        if (vendor) {
            if (Object.values(vendor).length === 0) {
                return false;
            }
            return (
                vendor?.company_name &&
                vendor?.company_name !== '' &&
                vendor?.email &&
                vendor?.email !== '' &&
                vendor?.company_address &&
                vendor?.company_address !== '' &&
                vendor?.first_name &&
                vendor?.first_name !== '' &&
                vendor?.last_name &&
                vendor?.last_name !== '' &&
                vendor?.phone_number &&
                vendor?.phone_number !== '' &&
                vendor?.bank_name &&
                vendor?.bank_name !== '' &&
                vendor?.bank_account &&
                vendor?.bank_account !== '' &&
                vendor?.bank_routing &&
                vendor?.bank_routing !== ''
            );
        } else {
            return false;
        }
    };

    useEffect(() => {
        setLoading(true);
        if (!billId) {
            return;
        }
        (async () => {
            try {
                // TODO: factor out api call to AppData, and add global loading state
                const res = await payLaterAPI.getBillDetails(billId);
                if (checkIfBankComplete(res.company) && isVendorComplete(res.vendor)) {
                    return navigate(`/${billId}/agree-on-terms/${offerId}`);
                }
                setData(res);
                setLoading(false);
            } catch (e) {
                console.log(e);
                setLoading(false);
                navigate('/error');
            }
        })();
    }, [billId]);

    const handleClose = () => {
        setOpen(false);
        setAddNew(false);
    };

    const addNewVendor = () => {
        setOpen(true);
        setAddNew(true);
    };

    const handleSubmit = vendor => {
        handleVendorSelect(vendor);
        handleClose();
    };

    const editVendor = () => {
        setOpen(true);
    };

    const handleVendorSelect = async vendor => {
        if (vendor) {
            // TODO: factor out api call to AddData, and add global loading state
            await payLaterAPI.postVendorToBill(billId, vendor);
            const updatedBill = await payLaterAPI.getBillDetails(billId);
            formik.setValues(values => ({
                ...values,
                vendor: updatedBill.vendor,
            }));
            setData(updatedBill);
        } else {
            formik.setValues(values => ({
                ...values,
                vendor: null,
            }));
        }
    };

    let hasBankAccount =
        data?.company.bank_account && data?.company.bank_name && data?.company.bank_routing;

    return (
        !isLoading &&
        !!billId &&
        !!data && (
            <>
                <Box sx={{ height: '100%' }}>
                    <Box mt={3} display="flex" justifyContent="center">
                        <Typography variant="h5" color="text.primary">
                            Confirm Payment Info
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            height: 'fit-content',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            pt: 4,
                            pb: 1,
                        }}
                    >
                        <Typography fontWeight="bold">Pay to</Typography>
                        <Button variant="text" endIcon={<PersonAddIcon />} onClick={addNewVendor}>
                            Add New Vendor
                        </Button>
                    </Box>

                    <Box
                        sx={{
                            '& > .MuiTextField-root': {
                                mt: 2,
                            },
                        }}
                    >
                        <Box display="flex" mt={2}>
                            <VendorSelect
                                handleSelect={handleVendorSelect}
                                selectedVendor={formik.values.vendor}
                            />
                            <Button
                                sx={{ height: 'fit-content' }}
                                disabled={isVendorEmpty(formik.values.vendor)}
                                onClick={editVendor}
                            >
                                Edit
                            </Button>
                        </Box>
                        {skipVendor ? (
                            <>
                                {!isVendorComplete(formik.values.vendor) && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 1,
                                        }}
                                    >
                                        <Alert sx={{ mt: 1 }} severity="warning">
                                            Please edit and complete the vendor billing to continue.
                                            If you don't have the information, please check the
                                            checkbox below and fill in the vendor's email.
                                        </Alert>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="warning"
                                                    value={requestVendor}
                                                    onChange={handleCheckChange}
                                                />
                                            }
                                            label="I don't have vendor's billing information."
                                        />
                                    </Box>
                                )}
                                <TextField
                                    name="vendor.email"
                                    size="small"
                                    label="Vendor Contact Email"
                                    type="string"
                                    fullWidth
                                    value={formik.values.vendor?.email || ''}
                                    onChange={formik.handleChange}
                                />
                            </>
                        ) : (
                            <>
                                {!isVendorComplete(formik.values.vendor) &&
                                    !isVendorEmpty(formik.values.vendor) && (
                                        <Alert sx={{ mt: 1 }} severity="warning">
                                            This vendor's information is incomplete. Please finish
                                            editing the vendor and submit to continue.
                                        </Alert>
                                    )}
                                {!isVendorEmpty(formik.values.vendor) && (
                                    <TextField
                                        name="vendor.email"
                                        size="small"
                                        label="Contact Email"
                                        type="string"
                                        fullWidth
                                        required
                                        value={formik.values.vendor?.email || ''}
                                        onChange={formik.handleChange}
                                        helperText={'We will let your vendor know this is paid'}
                                    />
                                )}
                            </>
                        )}
                    </Box>

                    <Box display="flex" flexDirection="column" my={5} gap={2}>
                        <Typography variant="subtitle1" color="text.primary">
                            Pay From
                        </Typography>

                        {hasBankAccount ? (
                            <TextField
                                size="small"
                                label="Your Bank Account"
                                type="string"
                                fullWidth
                                required
                                value={`${data?.company.bank_name} - ${maskAccountNumber(
                                    data?.company?.bank_account || ''
                                )}`}
                                disabled
                            />
                        ) : (
                            <>
                                <TextField
                                    name="company.bank_name"
                                    label="Bank Name"
                                    fullWidth
                                    required
                                    type="string"
                                    size="small"
                                    value={formik.values.company.bank_name ?? ''}
                                    onChange={formik.handleChange}
                                />
                                <TextField
                                    name="company.bank_account"
                                    label="Account Number"
                                    fullWidth
                                    required
                                    type="string"
                                    size="small"
                                    value={formik.values.company.bank_account ?? ''}
                                    onChange={formik.handleChange}
                                />
                                <TextField
                                    name="company.bank_routing"
                                    label="Routing Number"
                                    fullWidth
                                    required
                                    type="string"
                                    size="small"
                                    value={formik.values.company.bank_routing ?? ''}
                                    onChange={formik.handleChange}
                                />
                            </>
                        )}
                    </Box>
                    {open && (
                        <VendorFormPopup
                            vendor={addNew ? null : formik.values.vendor}
                            open={open}
                            handleClose={handleClose}
                            handleSubmit={handleSubmit}
                        />
                    )}
                </Box>
                <BottomBar
                    action={
                        <Button
                            id="btn-pl-confirm-bank"
                            variant="contained"
                            color="primary"
                            size="medium"
                            fullWidth
                            disabled={!formik.isValid}
                            onClick={formik.handleSubmit}
                        >
                            Confirm
                        </Button>
                    }
                />
            </>
        )
    );
};
