import { Grid, Link, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import { Logo } from '@lendica/components';

const useFooterStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.card,
        color: theme.palette.text.disabled,
    },
    icons: {
        '& > a': {
            paddingLeft: theme.spacing(1),
        },
    },
}));

export const Footer = () => {
    const classes = useFooterStyles();

    return (
        <Box px={2} py={4} className={classes.root}>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Typography component={Box} pb={1} variant="body2">
                        <Link
                            href="https://www.golendica.com/terms"
                            target="_blank"
                            rel="noopener noreferrer"
                            color="inherit"
                        >
                            Terms
                        </Link>{' '}
                        |{' '}
                        <Link
                            href="https://www.golendica.com/privacy"
                            target="_blank"
                            rel="noopener noreferrer"
                            color="inherit"
                        >
                            Privacy
                        </Link>
                    </Typography>
                    <Typography component="div" variant="caption">
                        One Boston Place | Boston, MA 02108
                    </Typography>
                    <Typography component="div" variant="caption">
                        info@golendica.com
                    </Typography>
                    <Typography component="div" variant="caption">
                        (617)-286-2390
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography component={Box} pb={1} variant="caption">
                        Powered by
                    </Typography>
                    <Link
                        href="https://www.golendica.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Logo height={19} color="#4A4A68" />
                    </Link>
                </Grid>
            </Grid>
            <Grid container justifyContent="space-between" component={Box} mt={8}>
                <Grid item>
                    <Typography component="div" variant="caption">
                        &copy; 2022 Lendica Corp. All rights reserved.
                    </Typography>
                </Grid>
                <Grid item className={classes.icons}>
                    <Link
                        href="https://twitter.com/golendica"
                        target="_blank"
                        rel="noopener noreferrer"
                        color="inherit"
                    >
                        <TwitterIcon fontSize="small" />
                    </Link>
                    <Link
                        href="https://www.instagram.com/golendica"
                        target="_blank"
                        rel="noopener noreferrer"
                        color="inherit"
                    >
                        <InstagramIcon fontSize="small" />
                    </Link>
                    <Link
                        href="https://www.linkedin.com/company/lendica"
                        target="_blank"
                        rel="noopener noreferrer"
                        color="inherit"
                    >
                        <LinkedInIcon fontSize="small" />
                    </Link>
                </Grid>
            </Grid>
        </Box>
    );
};
