import { Box, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import NumberFormat from 'react-number-format';
import { RoundedButton, PlaceAutocomplete } from '@lendica/components';
import { StateSelect } from '../components/StateSelect';
import * as api from '../api/index';
import { getState, getStateAbbr } from '../utils/state';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
    row: {
        display: 'flex',
        gap: '1rem',
        flexDirection: 'row',

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
}));

const validationSchema = yup.object({
    phone_number: yup.string().required('Phone number is required'),
    country: yup.string(),
    first_name: yup.string(),
    last_name: yup.string().required('Last name is required'),
    address: yup.string().required('Address is required'),
    address_2: yup.string(),
    city: yup.string().required('City is required'),
    province: yup.string().required('State is required'),
    province_code: yup.string().required('State is required'),
    zip_code: yup.number('Please enter a valid zip code').required('Zip code is required'),
});

export const ShippingInfoForm = ({ billId, billDetails }) => {
    const formik = useFormik({
        initialValues: {
            phone_number: '',
            country: '',
            first_name: '',
            last_name: '',
            address: '',
            address_2: '',
            city: '',
            province: '',
            province_code: '',
            zip_code: '',
        },
        validationSchema: validationSchema,
        onSubmit: async values => {
            const shipping_details = {
                shipping_details: {
                    shipping_first_name: values.first_name,
                    shipping_last_name: values.last_name,
                    shipping_phone: values.phone_number,
                    shipping_address1: values.address,
                    shipping_address2: values.address_2,
                    shipping_city: values.city,
                    shipping_province: values.province,
                    shipping_province_code: values.province_code,
                    shipping_zip: values.zip_code,
                },
            };
            const res = await api.postShippingDetails(billId, shipping_details);
            handleNext();
        },
    });

    useEffect(() => {
        const fetchCompanySummary = async () => {
            const companySummary = await api.getCompany();
            formik.setFieldValue('phone_number', companySummary.company.phone_number);
        };
        fetchCompanySummary();
    }, []);

    useEffect(() => {
        if (billDetails.length !== 0) {
            let shipping_details = billDetails.shipping_details;
            formik.setValues({
                phone_number: shipping_details.shipping_phone,
                first_name: shipping_details.shipping_first_name,
                last_name: shipping_details.shipping_last_name,
                address: shipping_details.shipping_address1,
                address_2: shipping_details.shipping_address2,
                city: shipping_details.shipping_city,
                province: shipping_details.shipping_province,
                province_code: shipping_details.shipping_province_code,
                zip_code: shipping_details.shipping_zip,
            });
        }
    }, [billDetails]);

    const navigate = useNavigate();

    const getAddressValues = address => {
        let address_values = {
            city: '',
            state: '',
        };

        let terms_length = address.terms.length;

        address_values['city'] = address.terms[terms_length - 3].value;
        address_values['state'] = address.terms[terms_length - 2].value;
        return address_values;
    };

    const handleAddressChange = async address => {
        let geocoded_address = await api.geocodeAddress(address.place_id);
        let zip_code = geocoded_address.address_components.find(x =>
            x.types.includes('postal_code')
        ).long_name;

        let { city, state } = getAddressValues(address);

        formik.setFieldValue('address', address.structured_formatting.main_text);
        formik.setFieldValue('city', city);
        formik.setFieldValue('province', getState(state));
        formik.setFieldValue('province_code', state);
        formik.setFieldValue('zip_code', zip_code);
    };

    const handleStateChange = state => {
        formik.setFieldValue('province', state);
        formik.setFieldValue('province_code', getStateAbbr(state));
    };

    const handleNext = () => {
        navigate(`../shipping-methods/${billId}`);
    };

    const classes = useStyles();

    return (
        <form onSubmit={formik.handleSubmit} style={{ margin: '1rem 0' }}>
            <Typography variant="h6" my={1}>
                Contact Information
            </Typography>

            <NumberFormat
                customInput={TextField}
                format="+1 (###) ###-####"
                label="Mobile number"
                name="phone_number"
                id="phone_number"
                value={formik.values.phone_number}
                onValueChange={({ value }) => {
                    formik.setFieldValue('phone_number', value);
                }}
                error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                helperText={formik.touched.phone_number && formik.errors.phone_number}
                fullWidth
                color="primary"
                margin="normal"
                size="small"
                sx={{ mb: 4 }}
            />

            <Typography variant="h6" my={2}>
                Shipping address
            </Typography>

            <Box my={2} mt={4}>
                <TextField
                    label="Country/Region"
                    name="country"
                    id="country"
                    value={'United States'}
                    onChange={formik.handleChange}
                    error={formik.touched.country && Boolean(formik.errors.country)}
                    helperText={formik.touched.country && formik.errors.country}
                    size="small"
                    fullWidth
                    color="primary"
                />
            </Box>

            <Box className={classes.row} mb={2}>
                <TextField
                    label="First Name (optional)"
                    name="first_name"
                    id="first_name"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                    helperText={formik.touched.first_name && formik.errors.first_name}
                    size="small"
                    fullWidth
                    sx={{ flex: 1 }}
                    color="primary"
                />
                <TextField
                    label="Last Name"
                    name="last_name"
                    id="last_name"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                    helperText={formik.touched.last_name && formik.errors.last_name}
                    size="small"
                    fullWidth
                    sx={{ flex: 1 }}
                    color="primary"
                />
            </Box>

            <Box sx={{ display: 'flex' }} mb={2}>
                <PlaceAutocomplete
                    label="Address"
                    onChange={handleAddressChange}
                    value={formik.values.address}
                    error={formik.touched.address && Boolean(formik.errors.address)}
                    helperText={formik.touched.address && formik.errors.address}
                />
            </Box>

            <Box sx={{ display: 'flex' }} mb={2}>
                <TextField
                    label="Apartment, suite, etc. (optional)"
                    name="address_2"
                    id="address_2"
                    onChange={formik.handleChange}
                    value={formik.values.address_2}
                    error={formik.touched.address_2 && Boolean(formik.errors.address_2)}
                    helperText={formik.touched.address_2 && formik.errors.address_2}
                    size="small"
                    fullWidth
                    sx={{ flex: 1 }}
                    color="primary"
                />
            </Box>

            <Box className={classes.row} mb={2}>
                <TextField
                    label="City"
                    name="city"
                    id="city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                    size="small"
                    fullWidth
                    sx={{ flex: 1 }}
                    color="primary"
                />

                <StateSelect
                    value={formik.values.province}
                    handleChange={handleStateChange}
                    error={formik.touched.province && Boolean(formik.errors.province)}
                    helperText={formik.touched.province && formik.errors.province}
                />

                <TextField
                    label="Zip Code"
                    name="zip_code"
                    id="zip_code"
                    value={formik.values.zip_code}
                    onChange={formik.handleChange}
                    error={formik.touched.zip_code && Boolean(formik.errors.zip_code)}
                    helperText={formik.touched.zip_code && formik.errors.zip_code}
                    size="small"
                    fullWidth
                    sx={{ flex: 1 }}
                    color="primary"
                />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <RoundedButton
                    sx={{ flex: 1, mt: 2 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Continue to shipping
                </RoundedButton>
            </Box>
        </form>
    );
};
