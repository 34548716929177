import { Logo } from '@lendica/components';
import { MarketingCard } from '../components/MarketingCard';
import { RoundedButton } from '@lendica/components';
import { Box, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useEffect } from 'react';
import * as api from '../api/index';
import { useState } from 'react';

const validationSchema = yup.object({
    password: yup.string().min(8, 'Password must be at least 8 digits long'),
});

export const GetStartedPage = ({ env }) => {
    const [company, setCompany] = useState({});

    const formik = useFormik({
        initialValues: {
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async values => {
            const res = await api.register({
                email: company.company.email,
                password: values.password,
                company_name: company.company.company_name,
            });
            if (env === 'prod') {
                window.location.replace('https://portal.app.golendica.com');
            } else {
                window.location.replace('https://portal.app-idev.golendica.com');
            }
        },
    });

    useEffect(() => {
        (async function () {
            let companySummary = await api.getCompany();
            setCompany(companySummary);
        })();
    }, []);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: 350,
                    width: '100%',
                    maxWidth: 600,
                    paddingX: 2,
                    boxSizing: 'border-box',
                }}
            >
                <Logo height={20} />
                {/* <BreadCrumbs page={3} company_id={company_id} /> */}
                <MarketingCard page={3} />

                <Typography variant="h6" fontSize={14} mt={2}>
                    Create a password for your account
                </Typography>

                <Box sx={{ height: 'fit-content' }}>
                    <TextField
                        label="Password"
                        name="password"
                        id="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        size="medium"
                        type="password"
                        fullWidth
                        sx={{ my: 2 }}
                        color="primary"
                    />
                </Box>

                <RoundedButton
                    variant="contained"
                    color="primary"
                    sx={{
                        flex: 1,
                        mt: 2,
                        height: 'fit-content',
                    }}
                    type="submit"
                    // href={'https://portal.app.golendica.com/login'}
                >
                    Create account and go to portal
                </RoundedButton>
            </Box>
        </form>
    );
};
