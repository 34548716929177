import { useEffect } from 'react';
import { useNavigate, useParams, Outlet } from 'react-router-dom';
// import { Form, applicationStatuses } from '@lendica/loan-form';
import Box from '@mui/material/Box';
import { ApplicationForm as Form, PreviewTermsCalculator } from '@lendica/application-form';
// import { getEnvFromHost } from '@lendica/utils';
import { useAppContainerContext } from './AppContainer';
import { useBillData } from './AppData';

import { flag } from './lib/states';

const applicationStatuses = {
    inprogress: 0,
    submitted: 1,
    approved: 2,
    declined: 3,
};

const getEnvFromHost = hostname => {
    hostname = hostname.toUpperCase();
    if (/SANDBOX/gi.test(hostname)) {
        return 'sandbox';
    }
    if (/DEV/gi.test(hostname)) {
        return 'idev';
    }
    if (/QA/gi.test(hostname)) {
        return 'iqa';
    }
    if (/LOCAL/gi.test(hostname) || /127\.0\.0\.1/gi.test(hostname)) {
        return 'idev';
    }
    return 'prod';
};

export const ApplicationForm = () => {
    const { billId, offerId } = useParams();
    const navigate = useNavigate();
    const { toggleFullscreen } = useAppContainerContext();
    const { bill } = useBillData();

    useEffect(() => {
        toggleFullscreen();
        return () => toggleFullscreen();
    }, []);

    // if (loading) {
    //     return (
    //         <Box
    //             sx={{
    //                 width: '100%',
    //                 height: '100%',
    //                 display: 'flex',
    //                 justifyContent: 'center',
    //                 alignItems: 'center',
    //             }}
    //         >
    //             <Flow />
    //         </Box>
    //     );
    // }

    // if (error) {
    //     return navigate(`/${billId}/error`);
    // }

    const isNewCheckout = flag.hasReturnUrl(bill);

    return (
        <Box sx={{ pt: 2, pb: 4 }}>
            <Form
                env={getEnvFromHost(window?.location?.hostname)}
                onComplete={application_status => {
                    if (flag.isShopifyCheckout({ bill })) {
                        switch (application_status) {
                            case applicationStatuses.approved:
                                navigate(`/${billId}/agree-on-terms/${offerId}`);
                                return;
                            case applicationStatuses.submitted:
                                navigate(`/${billId}/shop/pending`);
                                return;
                            case applicationStatuses.declined:
                                navigate(`/${billId}/shop/sorry`);
                                return;
                            default:
                                navigate(`/${billId}/pending`);
                                return;
                        }
                    } else {
                        switch (application_status) {
                            case applicationStatuses.approved:
                                if (flag.isWholesaleCheckout(bill)) {
                                    navigate(`/${billId}/agree-on-terms/${offerId}`);
                                } else {
                                    navigate(`/${billId}/confirm-bank/${offerId}`);
                                }
                                return;
                            case applicationStatuses.submitted:
                                isNewCheckout
                                    ? navigate(`/${billId}/checkout/redirect`)
                                    : navigate(`/${billId}/pending`);
                                return;
                            case applicationStatuses.declined:
                                isNewCheckout
                                    ? navigate(`/${billId}/checkout/redirect`)
                                    : navigate(`/${billId}/rejected`);
                                return;
                            default:
                                isNewCheckout
                                    ? navigate(`/${billId}/checkout/redirect`)
                                    : navigate(`/${billId}/pending`);
                                return;
                        }
                    }
                }}
            >
                <Outlet />
            </Form>
        </Box>
    );
};

export const OfferCalculator = () => {
    const { toggleFullscreen } = useAppContainerContext();
    useEffect(() => {
        toggleFullscreen();
        return () => toggleFullscreen();
    }, []);
    return <PreviewTermsCalculator />;
};
