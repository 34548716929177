import { useState } from 'react';
import { Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import WifiProtectedSetupIcon from '@mui/icons-material/WifiProtectedSetup';

import {
    Overlay,
    OverlayFieldset,
    OverlayKeypoint,
    MoreInformation,
    Terms,
    FootNote,
} from '@lendica/components';

import { BottomBar } from './BottomBar';
import { PaymentOffer } from './PaymentOffer';
import { currencyFormatter } from '@lendica/utils';

const useStyles = makeStyles(theme => ({
    paymentTerm: {
        'backgroundColor': theme.palette.background.cardLight,
        'borderTop': '1px solid #D7D7E0',
        'borderBottom': '1px solid #D7D7E0',
        'cursor': 'unset',
        '&:hover': {
            cursor: 'unset',
        },
    },
    snackbarButton: {
        color: theme.palette.error.main,
    },
    button: {
        borderRadius: 30,
        boxShadow: 'none',
        width: '100%',
    },
}));

export const AgreeOnTerms = ({ onGoBack, onAgree, offer }) => {
    const classes = useStyles();

    const [agreeTerms, setAgreeTerms] = useState(false);

    return (
        <>
            <Overlay title="Agree and pay over time" handleClose={() => onGoBack()}>
                <PaymentOffer className={classes.paymentTerm} offer={offer} />
                <Box display="flex" justifyContent="flex-end" mt={1} pr={2}>
                    <Button
                        size="small"
                        variant="text"
                        color="info"
                        endIcon={<WifiProtectedSetupIcon fontSize="small" />}
                        onClick={() => onGoBack()}
                    >
                        Change Term Option
                    </Button>
                </Box>
                <OverlayFieldset subtitle="What you'll get">
                    <OverlayKeypoint
                        summary="Amount Financed"
                        details={currencyFormatter(offer?.advance_amount ?? 0)}
                    />
                </OverlayFieldset>
                <OverlayFieldset subtitle="What you'll pay">
                    <OverlayKeypoint
                        summary="Due Today"
                        details={currencyFormatter(
                            (offer?.total_price ?? 0) -
                                (offer?.advance_amount ?? 0) +
                                (offer?.origination_fee ?? 0)
                        )}
                    />
                    <OverlayKeypoint
                        summary="Total Future Payments*"
                        details={currencyFormatter(offer?.total_payments ?? 0)}
                    />
                    <OverlayKeypoint
                        summary="Total Payments"
                        details={currencyFormatter(
                            (offer?.total_price ?? 0) -
                                (offer?.advance_amount ?? 0) +
                                (offer?.origination_fee ?? 0) +
                                offer?.total_payments ?? 0
                        )}
                    />
                </OverlayFieldset>
                <OverlayFieldset subtitle="How you’ll pay">
                    <OverlayKeypoint
                        summary="Weekly payments of"
                        details={currencyFormatter(offer?.weekly_payment ?? 0)}
                    />
                </OverlayFieldset>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box sx={{ width: '80%' }}>
                        <MoreInformation
                            items={[
                                {
                                    summary: 'Late Fee',
                                    details:
                                        'If the undersigned fails to make a payment, a fee will be applied for each day the payment is overdue.',
                                },
                                {
                                    summary: 'First Payment',
                                    details:
                                        'The first payment will be scheduled for one calendar week after the undersigned and the seller confirm receipt of this order.',
                                },
                            ]}
                        />
                    </Box>
                </Box>
                <Terms
                    termsToSign={{
                        text: `I agree to the PayLater Terms and Services and confirm that I am a controlling member of the business named in the application and have authority to act on its behalf`,
                        url: 'https://www.golendica.com/agreement-paylater',
                    }}
                    checked={agreeTerms}
                    onChange={e => setAgreeTerms(e.target.checked)}
                />
                <FootNote lines={['*Fees are subject to change with each invoice funded.']} />
            </Overlay>

            <BottomBar
                action={
                    <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        className={classes.button}
                        disabled={!agreeTerms}
                        onClick={() => {
                            onAgree();
                        }}
                    >
                        Agree
                    </Button>
                }
            />
        </>
    );
};
