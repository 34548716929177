import { flag } from './states';

export const redirectToUrl = url => {
    if (window?.location?.ancestorOrigins?.length > 0) {
        window.parent.location = url;
    } else {
        window.location = url;
    }
};

export const addBillLogic = ({ billId, offerId, data }) => {
    const isShopify = flag.isShopifyCheckout({ company: data?.company, bill: data?.bill });
    const redirectLinks = {
        application: [
            `/${billId}/${offerId}/application`,
            `/${billId}/${offerId}/shipping-info/${billId}`,
        ],
        offers: [`/${billId}/select-terms/final`, `/${billId}/${offerId}/shipping-info/${billId}`],
        wait: [`/${billId}/pending`, `/${billId}/shop/pending`],
        rejected: [`/${billId}/declined`, `/${billId}/shop/sorry`],
    };
    switch (data?.next_step) {
        case 'application':
        case 'offers':
            return redirectLinks[data?.next_step][+isShopify];
        case 'wait':
        case 'rejected':
            if (flag.hasReturnUrl(data?.bill)) {
                redirectToUrl(data?.bill?.return_url);
                return null;
            }
            return redirectLinks[data?.next_step][+isShopify];
    }
};
