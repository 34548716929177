import { currencyFormatter } from '@lendica/utils';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { format, parseISO } from 'date-fns';

export const DueDatePaymentCard = ({ open = false, offer, bill }) => {
    const getDeposit = (totalPrice, advanceAmount) => {
        return totalPrice - advanceAmount;
    };

    const getPaymentOnDueDate = (totalPrice, advanceAmount, originationFee) => {
        return getDeposit(totalPrice, advanceAmount) + originationFee;
    };

    const parseDueDate = dueDate => {
        if (dueDate) {
            return format(parseISO(dueDate), 'MMM d, yyyy');
        } else {
            return format(new Date(), 'MMM d, yyyy');
        }
    };

    return (
        !!offer &&
        !!bill && (
            <Accordion
                sx={{
                    'boxShadow': 0,
                    'borderRadius': 2,
                    'border': 'none',
                    'background': '#F4F4F6',
                    'boxSizing': 'border-box',
                    'mb': 2,
                    'width': '100%',
                    '&:before': {
                        display: 'none',
                    },
                }}
            >
                <AccordionSummary expandIcon={<ArrowDropDown color="info.main" />}>
                    <Typography
                        fontSize={13}
                        fontWeight={500}
                        color={'info.main'}
                    >{`${currencyFormatter(
                        getPaymentOnDueDate(
                            offer.total_price,
                            offer.advance_amount,
                            offer.origination_fee
                        )
                    )} due on ${parseDueDate(bill.due_date)}`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            mb: 1.5,
                        }}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography
                                color={'text.secondary'}
                                variant="caption"
                                fontWeight={400}
                                fontSize={12}
                            >
                                Amount funded
                            </Typography>
                            <Typography
                                color={'text.primary'}
                                variant="h4"
                                fontSize={14}
                                fontWeight={500}
                            >
                                {currencyFormatter(offer.advance_amount)}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography
                                color={'text.secondary'}
                                variant="caption"
                                fontWeight={400}
                                fontSize={12}
                                textAlign="left"
                            >
                                You pay
                            </Typography>
                            <Typography
                                color={'text.primary'}
                                variant="h4"
                                fontSize={14}
                                fontWeight={500}
                                textAlign="left"
                            >{`${currencyFormatter(
                                getPaymentOnDueDate(
                                    offer.total_price,
                                    offer.advance_amount,
                                    offer.origination_fee
                                )
                            )}*`}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography
                                color={'text.secondary'}
                                variant="caption"
                                fontWeight={400}
                                fontSize={12}
                                textAlign="right"
                            >
                                Your vendor receives
                            </Typography>
                            <Typography
                                color={'text.primary'}
                                variant="h4"
                                fontSize={14}
                                fontWeight={500}
                                textAlign="right"
                            >
                                {currencyFormatter(offer.total_price)}
                            </Typography>
                        </Box>
                    </Box>

                    <Typography
                        mb={1}
                        mt={0.5}
                        color={'text.secondary'}
                        variant="caption"
                        fontWeight={400}
                        fontSize={12}
                        textAlign="left"
                    >{`*${currencyFormatter(
                        offer.origination_fee
                    )} processing fee + ${currencyFormatter(
                        getDeposit(offer.total_price, offer.advance_amount)
                    )} deposit amount`}</Typography>
                </AccordionDetails>
            </Accordion>
        )
    );
};
