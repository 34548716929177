import { Box, Typography, CircularProgress, Button } from '@mui/material';
import { useEffect, useState } from 'react';

const CircularProgressTimer = ({ total, onComplete }) => {
    const [timer, setTimer] = useState(total);

    useEffect(() => {
        if (total === 0) {
            onComplete();
            return;
        }
        const intervalId = setInterval(() => {
            setTimer(currentValue => {
                if (currentValue === 0) {
                    clearInterval(intervalId);
                    onComplete();
                    return currentValue;
                }
                return currentValue - 1;
            });
        }, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress
                color="primary"
                variant="determinate"
                value={((total + 1 - timer) / (total + 1)) * 100}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="primary"
                >{`${timer}s`}</Typography>
            </Box>
        </Box>
    );
};

export const StatusPage = ({
    title,
    subtitle,
    duration,
    action,
    actionLabel,
    secondaryAction = null,
    secondaryActionLabel = null,
}) => {
    return (
        <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <Box>
                <Typography variant="h5" color="text.secondary" align="center" paragraph>
                    {title}
                </Typography>
            </Box>
            <Box mt={2} sx={{ width: '80%' }}>
                <Typography variant="subtitle1" color="secondary" align="center" paragraph>
                    {subtitle}
                </Typography>
            </Box>
            {!!action && (
                <>
                    {!!duration && duration > 0 && (
                        <Box mt={2} display="flex" justifyContent="center">
                            <CircularProgressTimer total={duration} onComplete={action} />
                        </Box>
                    )}
                    <Box mt={4} display="flex" justifyContent="center">
                        <Button variant="contained" color="primary" fullWidth onClick={action}>
                            {actionLabel}
                        </Button>
                    </Box>
                </>
            )}
            {!!secondaryAction && (
                <>
                    <Box mt={2} display="flex" justifyContent="center">
                        <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={secondaryAction}
                        >
                            {secondaryActionLabel}
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    );
};
