import axios from 'axios';
import { Box, TextField, Button, Typography } from '@mui/material';
import { useState } from 'react';
import NumberFormat from 'react-number-format';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
import { PlaceAutocomplete } from '@lendica/components';
import {
    hasNumberOfDigits,
    isNumber,
    isNumberOfDigitsInRange,
    // isValidRoutingNumber,
} from '@lendica/utils';

const useStyles = makeStyles(theme => ({
    row: {
        display: 'flex',
        gap: '1rem',
        flexDirection: 'row',

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
}));

export const VendorForm = ({ vendor, callback, handleClose }) => {
    const [loading, setLoading] = useState(false);

    let validationSchema = yup.object({
        company_name: yup.string().nullable().required('Please enter the vendor name'),
        first_name: yup.string().nullable().required('Field is required'),
        last_name: yup.string().nullable().required('Field is required'),
        phone_number: yup.number().nullable().required('Field is required'),
        email: yup
            .string()
            .nullable()
            .email('Please enter a valid email')
            .required('Field is required'),
        company_address: yup.string().nullable().required('Field is required'),
        bank_name: yup.string().nullable().required('Field is required'),
        bank_account: yup
            .string()
            .trim()
            .required('Field is required')
            .test('match', 'Must be a number', isNumber)
            .test(
                'len',
                'Must have at least 5 and less than 18 digits',
                isNumberOfDigitsInRange(5, 18)
            ),
        bank_routing: yup
            .string()
            .trim()
            .required('Field is required')
            .test('match', 'Must be a number', isNumber)
            .test('len', 'Routing number must be 9 digits', hasNumberOfDigits(9)),
        // .test('is-valid-routing-number', 'Invalid routing number', isValidRoutingNumber)
    });

    let formik = useFormik({
        initialValues: {
            company_name: vendor ? vendor.company_name : '',
            first_name: vendor ? vendor.first_name : '',
            last_name: vendor ? vendor.last_name : '',
            phone_number: vendor ? vendor.phone_number : '',
            email: vendor ? vendor.email : '',
            company_address: vendor ? vendor.company_address : '',
            bank_name: vendor ? vendor.bank_name : '',
            bank_account: vendor ? vendor.bank_account : '',
            bank_routing: vendor ? vendor.bank_routing : '',
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            setLoading(true);
            setTimeout(async () => {
                try {
                    if (vendor) {
                        const res = (await axios.post('/company/vendors', { ...vendor, ...values }))
                            .data;
                        callback(res);
                    } else {
                        const res = (await axios.post('/company/vendors', values)).data;
                        callback(res);
                    }
                } catch (e) {
                    console.log(e);
                    setLoading(false);
                }
            }, 1000);
        },
        enableReinitialize: true,
    });

    const handleAddressChange = address => {
        formik.setFieldValue('company_address', address.description);
    };

    const classes = useStyles();

    return (
        <form onSubmit={formik.handleSubmit}>
            <Typography variant="h6">Vendor</Typography>
            <Box className={classes.row} mb={2}>
                <TextField
                    label="Vendor Name"
                    name="company_name"
                    id="company_name"
                    value={formik.values.company_name}
                    onChange={formik.handleChange}
                    error={formik.touched.company_name && Boolean(formik.errors.company_name)}
                    helperText={formik.touched.company_name && formik.errors.company_name}
                    size="small"
                    fullWidth
                    sx={{ flex: 1 }}
                    color="primary"
                />
            </Box>

            <Typography variant="h6" mb={1}>
                Contact Info
            </Typography>
            <Box className={classes.row} mb={1}>
                <TextField
                    label="First Name"
                    name="first_name"
                    id="first_name"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                    helperText={formik.touched.first_name && formik.errors.first_name}
                    size="small"
                    fullWidth
                    sx={{ flex: 1 }}
                    color="primary"
                />
                <TextField
                    label="Last Name"
                    name="last_name"
                    id="last_name"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                    helperText={formik.touched.last_name && formik.errors.last_name}
                    size="small"
                    fullWidth
                    sx={{ flex: 1 }}
                    color="primary"
                />
            </Box>

            <Box className={classes.row} my={1}>
                <TextField
                    label="Email"
                    name="email"
                    id="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    size="small"
                    fullWidth
                    sx={{ flex: 1 }}
                    color="primary"
                />
            </Box>

            <Box sx={{ display: 'flex' }} mb={1} mt={1}>
                <NumberFormat
                    customInput={TextField}
                    format="###-###-####"
                    label="Phone Number"
                    name="phone_number"
                    id="phone_number"
                    value={formik.values.phone_number}
                    // onChange={formik.handleChange}
                    onValueChange={({ value }) => {
                        formik.setFieldValue('phone_number', value);
                    }}
                    error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                    helperText={formik.touched.phone_number && formik.errors.phone_number}
                    fullWidth
                    sx={{ flex: 1 }}
                    color="primary"
                    size="small"
                />
            </Box>

            <Box sx={{ display: 'flex' }} mb={2}>
                <PlaceAutocomplete
                    label="Company Address"
                    onChange={handleAddressChange}
                    value={formik.values.company_address}
                    error={formik.touched.company_address && Boolean(formik.errors.company_address)}
                    helperText={formik.touched.company_address && formik.errors.company_address}
                />
            </Box>

            <Typography variant="h6" mb={1}>
                Bank Info
            </Typography>

            <Box sx={{ display: 'flex' }} my={1}>
                <TextField
                    label="Bank Name"
                    name="bank_name"
                    id="bank_name"
                    value={formik.values.bank_name}
                    onChange={formik.handleChange}
                    error={formik.touched.bank_name && Boolean(formik.errors.bank_name)}
                    helperText={formik.touched.bank_name && formik.errors.bank_name}
                    size="small"
                    fullWidth
                    sx={{ flex: 1 }}
                    color="primary"
                />
            </Box>

            <Box sx={{ display: 'flex' }} my={1}>
                <NumberFormat
                    customInput={TextField}
                    format="#############"
                    label="Bank Account #"
                    name="bank_account"
                    id="bank_account"
                    value={formik.values.bank_account}
                    onChange={formik.handleChange}
                    error={formik.touched.bank_account && Boolean(formik.errors.bank_account)}
                    helperText={formik.touched.bank_account && formik.errors.bank_account}
                    fullWidth
                    sx={{ flex: 1 }}
                    color="primary"
                    size="small"
                />
            </Box>

            <Box sx={{ display: 'flex' }} mb={2} mt={1}>
                <NumberFormat
                    customInput={TextField}
                    format="#############"
                    label="Bank Routing #"
                    name="bank_routing"
                    id="bank_routing"
                    value={formik.values.bank_routing}
                    onChange={formik.handleChange}
                    error={formik.touched.bank_routing && Boolean(formik.errors.bank_routing)}
                    helperText={formik.touched.bank_routing && formik.errors.bank_routing}
                    fullWidth
                    sx={{ flex: 1 }}
                    color="primary"
                    size="small"
                />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <Button onClick={handleClose}>Cancel</Button>
                <LoadingButton loading={loading} type="submit">
                    Submit
                </LoadingButton>
            </Box>
        </form>
    );
};
